function getGoogleClickIDFromCookie() {
    if (typeof document === 'undefined') {
        return undefined;
    }

    const cookieName = 'gid';
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cookieName}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


function getRuidTokenFromCookie() {
    if (typeof document === 'undefined') {
        return undefined;
    }

    const cookieName = 'ruid=';
    const ca = document.cookie.split(/;\s*/);

    for (let i = ca.length - 1; i >= 0; i--) {
        if (!ca[i].indexOf(cookieName)) {
            return ca[i].replace(cookieName, '');
        }
    }

    return undefined;
}


// IP Address Capture
const fetchIpAddress = async (ipVariable) => {
    // Get IP Address from an external link to fetch the global IP
    const getIPAddress = async () => {
        try {
            const response = await fetch(
                "https://europe-north1-heyflow-custom-solutions.cloudfunctions.net/fetchIp"
            );
            const data = await response.json();
            if (data.ip) {
                return data.ip;
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    try {
        const ipInputField = document.querySelector(
            `[data-variable="${ipVariable}"]`
        );

        if (ipInputField != null) {
            const ipAddress = await getIPAddress();
            ipInputField.value = ipAddress;
        }
    } catch (error) {
        console.error("Error: ", error);
    }
};

function getInputByName(name) {
    return document.querySelector(`[data-variable]="${name}"`);
}

window.addEventListener('heyflow-init', event => {
    fetchIpAddress("ipAddress");
})

window.addEventListener('heyflow-screen-view', (event) => {

    if (event.detail.screenName == 'contact') {
        const ruid = getRuidTokenFromCookie();

        if (ruid) {
            const ruidInput = getInputByName('ruid');
            ruidInput.value = ruid;
            ruidInput.dispatchEvent(new Event('input'));
        }

        const gclickid = getGoogleClickIDFromCookie();
        if (gclickid) {
            const gclickidInput = getInputByName('gclickid');
            gclickidInput.value = gclickid;
            gclickidInput.dispatchEvent(new Event('input'));
        }
    }
});

window.addEventListener('heyflow-submit', (event) => {});